import Vue from 'vue';
import Router from 'vue-router';
import InstantSearch from 'vue-instantsearch';
import appStore from '@/store';
import UserIndexSearchService from '@/views/repository/user/search/UserIndexSearchService';

Vue.use(InstantSearch);
Vue.use(Router);

function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/${component}.vue`);
}

let previousRoute = '';
const routes = [
  {
    path: '/',
    component: load('views/parts/Layout'),
    children: [
      {
        path: '/',
        name: 'home',
        beforeEnter: async (to, _, next) => {
          next({ name: 'dashboard' });
        },
        meta: {public: true},
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: load('views/Dashboard'),
        meta: {visibleWhenOrganizationSelected: false},
      },

      {
        path: '/admin/indexing',
        name: 'indexing_dashboard',
        component: load('views/Admin/IndexingDashboard'),
      },
      {
        path: '/admin/ats',
        name: 'simulate_ats_entry',
        component: load('views/Admin/SimulateAtsEntry'),
      },

      {
        path: '/admin/recruitment/:recruitment_id/generate_candidatures',
        name: 'generate_candidatures',
        component: load('views/Admin/GenerateCandidature'),
      },

      // Capacity
      {
        path: '/repository/capacity',
        name: 'capacities_repository',
        component: load('views/repository/capacity/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },

      // Activity
      {
        path: '/repository/activity/:type/list',
        name: 'activities_repository',
        component: load('views/repository/activity/List'),
        meta: {visibleWhenOrganizationSelected: false},
        props: route => ({
          ...route.params,
        }),
      },
      {
        path: '/repository/activity/:type/create',
        name: 'activities_create',
        component: load('views/repository/activity/Edit'),
        meta: {visibleWhenOrganizationSelected: false},
        props: route => ({
          ...route.params,
        }),
      },
      {
        path: '/repository/activity/:type/edit/:id',
        name: 'activities_edit',
        component: load('views/repository/activity/Edit'),
        meta: {visibleWhenOrganizationSelected: false},
        props: route => ({
          ...route.params,
        }),
      },
      {
        path: '/repository/activity/merge',
        name: 'activities_merge',
        component: load('views/repository/activity/merge/MergeActivitiesView'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      // Context
      {
        path: '/repository/contexts',
        name: 'contexts_repository',
        component: load('views/repository/context/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/context',
        name: 'contexts_create',
        component: load('views/repository/context/Form'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/context/:contextId',
        name: 'contexts_edit',
        component: load('views/repository/context/Form'),
        meta: {visibleWhenOrganizationSelected: false},
        props: route => ({
          ...route.params,
        }),
      },
      // QuestionForContexts
      {
        path: '/repository/questions-for-contexts',
        name: 'questions_for_contexts_repository',
        component: load('views/repository/questionForContexts/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/question-for-contexts/edit/:questionForContextId',
        name: 'questions_for_contexts_edit',
        component: load('views/repository/questionForContexts/Edit'),
        meta: {visibleWhenOrganizationSelected: false},
        props: route => ({
          ...route.params,
        }),
      },
      {
        path: '/repository/question-for-contexts/create',
        name: 'questions_for_contexts_create',
        component: load('views/repository/questionForContexts/Edit'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      // Behavior
      {
        path: '/repository/behaviors',
        name: 'behaviors_repository',
        component: load('views/repository/behavior/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      // Category
      {
        path: '/repository/categories',
        name: 'categories_repository',
        component: load('views/repository/category/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },

      // ERHGO Occupation
      {
        path: '/repository/erhgo-occupation',
        name: 'erhgo_occupation_repository',
        component: load('views/repository/erhgo/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/erhgo-occupation/merge',
        name: 'merge_erhgo_occupations',
        component: load('views/repository/erhgo/MergeErhgoOccupations'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/erhgo-occupation/:id',
        name: 'erhgo_occupation_detail',
        component: load('views/repository/erhgo/ErhgoOccupationDetail'),
        meta: {visibleWhenOrganizationSelected: false},
      },

      // ERHGO Occupation for OT
      {
        path: '/repository/organization/:organization_code/erhgo-occupation',
        name: 'organizations_jobs_list',
        component: load('views/repository/organization/erhgo/List'),
        meta: {visibleWhenOrganizationSelected: true},
      },

      // ESCO Occupation
      {
        path: '/repository/esco-occupation/skill/:uri',
        name: 'skill_detail',
        component: load('views/repository/esco/SkillDetail'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/esco-occupation/:uri',
        name: 'occupation_detail',
        component: load('views/repository/esco/EscoOccupationDetail'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      // Compare capacities
      {
        path: '/repository/compare',
        name: 'compare_jobs',
        component: load('views/repository/compare/CompareCapacitiesList'),
      },
      //  Organization
      {
        path: '/setup/organizations',
        name: 'organizations_repository',
        component: load('views/setup/organization/ListPage'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      //  List invitaion code
      {
        path: '/setup/invitationCode',
        name: 'invitation_code_repository',
        component: load('views/setup/invitationCode/InvitationCodeList'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/setup/:organization_code/organizations',
        name: 'employer_repository',
        component: load('views/setup/organization/ListPage'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          organizationCode: route.params.organization_code,
        }),
      },
      {
        path: '/setup/organization/create',
        name: 'organizations_create',
        component: load('views/setup/organization/Edit'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/setup/organization/edit/:id',
        name: 'organizations_edit',
        component: load('views/setup/organization/Edit'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/setup/:organization_code/organization/create/',
        name: 'employer_create',
        component: load('views/setup/organization/Edit'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          organizationCode: route.params.organization_code,
        }),
      },
      {
        path: '/setup/:organization_code/organization/edit/:id',
        name: 'employer_edit',
        component: load('views/setup/organization/Edit'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          organizationCode: route.params.organization_code,
        }),
      },

      // Administrator
      {
        path: '/repository/administrator',
        name: 'administrators_repository',
        component: load('views/repository/administrator/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/administrator/create',
        name: 'administrators_create',
        component: load('components/common/crud/CreateUser'),
        meta: {visibleWhenOrganizationSelected: false},
        props: {createAdmin: true},
      },


      // Front-office users
      {
        path: '/setup/organization/:organization_code/front-users',
        name: 'front_user_list_by_organization',
        component: load('views/setup/user/ListPageForOrganization'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          organizationCode: route.params.organization_code,
          ...route.params,
        }),
      },
      {
        path: '/setup/organization/:organization_code/front-users/job/:job_id',
        name: 'front_user_list_by_job',
        component: load('views/setup/user/ListPageForJob'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          organizationCode: route.params.organization_code,
          ...route.params,
        }),
      },
      {
        path: '/setup/organization/:organization_code/user-index/for-job/:jobId',
        name: 'user-index-for-job-for-orga',
        component: load('views/repository/user/search/JobUserPage'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          ...route.params,
        }),

      },

      {
        path: '/setup/organization/:organization_code/front-users/:userId/detail',
        name: 'front_user_detail_for_organization',
        component: load('views/repository/user/detail/Detail'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          organizationCode: route.params.organization_code,
          ...route.params,
        }),
      },
      {
        path: '/repository/front-users',
        name: 'front_user_list',
        component: load('views/repository/user/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/front-users/:userId/detail',
        name: 'front_user_detail',
        component: load('views/repository/user/detail/Detail'),
        meta: {visibleWhenOrganizationSelected: false},
        props: route => ({
          ...route.params,
        }),
      },
      // Employee
      {
        path: '/setup/organization/:organization_code/employee/:employer_code?',
        name: 'organizations_employees_repository',
        component: load('views/setup/employee/List'),
        meta: {visibleWhenOrganizationSelected: true},
      },

      // jobs
      {
        path: '/setup/organization/:organization_code/job/list',
        name: 'organizations_jobs_repository',
        component: load('views/setup/job/List'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          ...route.params,
        }),
      },
      {
        path: '/setup/organization/:organization_code/job/:mode/:job_id?',
        name: 'organizations_jobs_edit',
        component: load('views/setup/job/Edit'),
        props: route => ({
          ...route.params,
          job_id: route.params.job_id,
          mode: route.params.mode,
        }),
        meta: {visibleWhenOrganizationSelected: true},
      },
      // Recruitment
      {
        path: '/setup/organization/:organization_code/recruitment/list',
        name: 'recruitments_list',
        component: load('views/setup/recruitment/list/RecruitmentList'),
        props: route => ({
          organizationCode: route.params.organization_code,
        }),
        meta: {visibleWhenOrganizationSelected: true},
      },
      {
        path: '/recruitment/list',
        name: 'admin_recruitments_list',
        component: load('views/setup/recruitment/list/RecruitmentList'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/setup/organization/:organization_code/recruitment/create',
        name: 'recruitment_create',
        component: load('views/setup/recruitment/Edit'),
        meta: {visibleWhenOrganizationSelected: true},
      },
      {
        path: '/setup/organization/:organization_code/recruitment/edit/:recruitmentCode',
        name: 'recruitment_edit',
        component: load('views/setup/recruitment/Edit'),
        meta: {visibleWhenOrganizationSelected: true},
      },

      {
        path: '/setup/organization/:organization_code/recruitment/:recruitment_id/result',
        name: 'recruitment_result',
        component: load('views/setup/recruitment/detail/result/List'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          ...route.params,
          recruitment_id: Number(route.params.recruitment_id),
        }),
      },
      {
        path: '/setup/organization/:organization_code/recruitment/:recruitmentCode/close',
        name: 'close_recruitment',
        component: load('views/setup/recruitment/StateChange'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          ...route.params,
          targetState: 'SELECTION',
        }),
      },
      {
        path: '/setup/organization/:organization_code/recruitment/:recruitmentCode/terminate',
        name: 'terminate_recruitment',
        component: load('views/setup/recruitment/StateChange'),
        meta: {visibleWhenOrganizationSelected: true},
        props: route => ({
          ...route.params,
          targetState: 'CLOSED',
        }),
      },

      // Recruitment profile
      {
        path: '/setup/organization/:organization_code/job/:job_id/recruitment_profile/create',
        name: 'recruitment_profile_create',
        component: load('views/setup/recruitment_profile/Edit'),
        props: route => ({
          ...route.params,
          job_id: route.params.job_id,
        }),
        meta: {visibleWhenOrganizationSelected: true},
      },
      {
        path: '/setup/organization/:organization_code/job/:job_id/recruitment_profile/edit/:profile_id',
        name: 'recruitment_profile_edit',
        component: load('views/setup/recruitment_profile/Edit'),
        props: route => ({
          ...route.params,
          job_id: route.params.job_id,
        }),
        meta: {visibleWhenOrganizationSelected: true},
      },

      // Candidature
      {
        path: '/setup/organization/:organization_code/candidature/:candidature_id',
        name: 'candidature_details',
        component: load('views/setup/candidature/detail/CandidatureDetail'),
        props: route => ({
          ...route.params,
          candidature_id: Number(route.params.candidature_id),
        }),
        meta: {visibleWhenOrganizationSelected: true},
      },
      {
        path: '/setup/organization/:organization_code/user-index',
        name: 'organization_user_index',
        component: load('views/repository/user/search/List'),
        props: route => ({initialService: new UserIndexSearchService(route.params.organizationCode)}),
        meta: {visibleWhenOrganizationSelected: true},
      },

      // Landing pages
      {
        path: '/repository/landing-page/list',
        name: 'list_landing_page',
        component: load('views/repository/landing_page/List'),
      },
      {
        path: '/repository/landing-page/:id?',
        name: 'edit_landing_page',
        component: load('views/repository/landing_page/Edit'),
        props: route => ({
          ...route.params,
        }),
      },

      // Extra-pro activity questions
      {
        path: '/repository/capacity-related-question/question/list',
        name: 'list_capacity_related_question',
        component: load('views/repository/capacity_related_question/List'),
        props: {
          questionType: 'EXTRAPROFESSIONAL',
        },
      },
      {
        path: '/repository/capacity-related-question/question/edit/:id?',
        name: 'edit_extra_pro_question',
        component: load('views/repository/capacity_related_question/Edit'),
        props: route => ({
          ...route.params,
          questionType: 'EXTRAPROFESSIONAL',
        }),
      },
      {
        path: '/repository/user-index',
        name: 'user-index',
        component: load('views/repository/user/search/AllUserPage'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/admin/sourcing/simulate',
        name: 'sourcing_simulated_result',
        component: load('views/repository/user/sourcing-simulator/SourcingSimulatorPage'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/user-edit/:userId',
        name: 'user-edit',
        component: load('views/repository/user/edit/EditUserPage'),
        meta: {visibleWhenOrganizationSelected: false},
        props: true,
      },
      {
        path: '/repository/user-index/for-job/:jobId',
        name: 'user-index-for-job',
        component: load('views/repository/user/search/JobUserPage'),
        meta: {visibleWhenOrganizationSelected: false},
        props: route => ({
          ...route.params,
        }),
      },
      {
        path: '/repository/criterias',
        name: 'criterias',
        component: load('views/repository/criterias/List'),
        meta: {visibleWhenOrganizationSelected: false},
      },
      {
        path: '/repository/question-for-criterias/edit/:criteriaCode',
        name: 'question_for_criterias_edit',
        component: load('views/repository/criterias/Edit'),
        meta: {visibleWhenOrganizationSelected: false},
        props: route => ({
          ...route.params,
        }),
      },
    ],
  },

  // Not found
  {
    path: '*',
    component: load('views/errors/404'),
    props: () => ({
      previousRoute,
    }),
    meta: {public: true},
  },

  // Unauthorized
  {
    path: '*',
    name: 'unauthorized',
    component: load('views/errors/401'),
    meta: {public: true},
  },
];

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({y: 0}),
  routes,
});

router.afterEach((to, from) => {
  previousRoute = from?.fullPath;
});

router.beforeEach((to, from, next) => {
  document.title = '#jenesuisPASunCV';
  next();
});

router.beforeEach(async (to, from, next) => {
  next();
});

router.beforeEach(async (to, from, next) => {
  if (!to.meta.visibleWhenOrganizationSelected) {
    next();
    return;
  }

  const loadedOrganization = appStore.getters.organizationLoaded;
  if (!loadedOrganization
    || (to.params.organization_code !== loadedOrganization.code
      // Next line: ensure this is NOT an employer
      && !!loadedOrganization.organizationType)
  ) {
    try {
      const selectedOrga = (await Vue.$api.getOrganizationByCode(to.params.organization_code)).data;
      appStore.commit('loadOrganization', selectedOrga);
    } catch (e) {
      // Failure => go to home
      next('/');
      return;
    }
  }
  next();
});

Vue.$router = router;

export default router;
